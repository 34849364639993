import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Auth from 'services/api/auth';
import {
  clearStorage,
  getAccessToken,
  getRememberValue,
  setRemember,
  setToken,
} from 'helpers/storage';

export const signUpAsync = createAsyncThunk('auth/signUp', async (data) => {
  try {
    const response = await Auth.signUp(data);
    setToken(response.data.access, response.data.refresh, localStorage);
    setRemember('remember');
    const getUserResponse = await Auth.getMe();
    return getUserResponse;
  } catch (e) {
    throw new Error(e);
  }
});

export const checkInvitationIdAsync = createAsyncThunk('auth/checkInvitation', async (uuid) => {
  try {
    const response = await Auth.checkInvitationId(uuid);
    return response;
  } catch (e) {
    throw new Error(e);
  }
});

export const signInAsync = createAsyncThunk(
  'auth/signIn',
  async ({ remember, ...data }, { rejectWithValue }) => {
    try {
      const response = await Auth.logIn(data);
      remember
        ? setToken(response.data.access, response.data.refresh, localStorage)
        : setToken(response.data.access, response.data.refresh, sessionStorage);
      setRemember(remember ? 'remember' : 'not-remember');
      const getUserResponse = await Auth.getMe();
      return getUserResponse;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const forgetPasswordAsync = createAsyncThunk(
  'auth/forgetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await Auth.forgetPassword(data);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const resetPasswordAsync = createAsyncThunk(
  'auth/resetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await Auth.resetPassword(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const changePasswordAsync = createAsyncThunk('auth/changePassword', async (data) => {
  try {
    const response = await Auth.changePassword(data);
    return response;
  } catch (e) {
    throw new Error(e);
  }
});

export const getMeAsync = createAsyncThunk('auth/getMe', async () => {
  try {
    const rememberValue = getRememberValue();
    const token = getAccessToken(rememberValue);
    if (!token) throw new Error('There is no token');
    const response = await Auth.getMe();
    return response;
  } catch (e) {
    throw new Error(e);
  }
});

const initialState = {
  remember: true,
  isAuthorized: false,
  currentUserData: null,
  invitedUserEmail: '',
  isInvalidId: false,
  status: '',
  error: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state) => {
      clearStorage();
      state.isAuthorized = false;
      state.currentUserData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpAsync.pending, (state) => {
        state.status = 'loading';
        state.error = '';
      })
      .addCase(signUpAsync.fulfilled, (state, action) => {
        state.isAuthorized = true;
        state.currentUserData = action.payload.data;
        state.status = 'idle';
      })
      .addCase(signUpAsync.rejected, (state, action) => {
        state.status = 'idle';
      })
      .addCase(checkInvitationIdAsync.pending, (state) => {
        state.isInvalidId = false;
        state.status = 'loading';
        state.invitedUserEmail = '';
        state.error = '';
      })
      .addCase(checkInvitationIdAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.invitedUserEmail = action.payload.data;
      })
      .addCase(checkInvitationIdAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.isInvalidId = true;
      })
      .addCase(signInAsync.pending, (state) => {
        state.isAuthorized = false;
        state.status = 'loading';
        state.error = '';
      })
      .addCase(signInAsync.fulfilled, (state, action) => {
        state.isAuthorized = true;
        state.currentUserData = action.payload.data;
        state.status = 'idle';
      })
      .addCase(signInAsync.rejected, (state, action) => {
        state.isAuthorized = false;
        state.status = 'idle';
        state.error = action.payload.detail;
      })
      .addCase(forgetPasswordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(forgetPasswordAsync.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(forgetPasswordAsync.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.status = 'idle';
      })
      .addCase(getMeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMeAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isAuthorized = true;
        state.currentUserData = action.payload.data;
      })
      .addCase(getMeAsync.rejected, (state) => {
        state.status = 'idle';
        state.isAuthorized = false;
      });
  },
});

export const { logOut } = authSlice.actions;

export default authSlice.reducer;
