import ApiClient from './base';

const Company = {
  getFilterOptions: () => ApiClient.get('/company/filter-options/'),
  getCompanies: ({ page, rowsPerPage, orderBy, search, order, filters }) => {
    const searching = search ? `&search=${search}` : '';
    const ordering = order === 'asc' ? orderBy : `-${orderBy}`;

    return ApiClient.get(
      `/company/?ordering=${ordering}&page=${page}&page_size=${rowsPerPage}${searching}&${new URLSearchParams(
        filters,
      )}`,
    );
  },
  createCompany: (data) => ApiClient.post('/company/', data),
  companyUploadCSV: (data) => ApiClient.postForm('/company/upload-csv/v2/', data),
  getCompanyDetails: (id) => ApiClient.get(`/company/${id}/`),
  getSingleCompanyBranches: ({ id, page, rowsPerPage }) =>
    ApiClient.get(`/company/${id}/branches/?page=${page}&page_size=${rowsPerPage}`),
  getSingleCompanyCustomers: ({ id, page, rowsPerPage }) =>
    ApiClient.get(`/company/${id}/customers/?page=${page}&page_size=${rowsPerPage}`),
  updateCompany: ({ data, id }) => ApiClient.patch(`/company/${id}/`, data),
  partialUpdateCompany: ({ data, id }) => ApiClient.patch(`/company/${id}/`, data),
  deleteCompany: (id) => ApiClient.delete(`/company/${id}`),

  createBranch: ({ id, data }) => ApiClient.post(`/company/${id}/branches/`, data),
  deleteBranch: ({ companyId, branchId }) =>
    ApiClient.delete(`/company/${companyId}/branches/${branchId}/`),
  updateBranch: ({ companyId, branchId, data }) =>
    ApiClient.put(`/company/${companyId}/branches/${branchId}/`, data),

  createCustomer: ({ companyId, data }) => ApiClient.post(`/company/${companyId}/customers/`, data),
  updateCustomer: ({ companyId, customerId, data }) =>
    ApiClient.put(`/company/${companyId}/customers/${customerId}/`, data),
  deleteCustomer: ({ companyId, customerId }) =>
    ApiClient.delete(`/company/${companyId}/customers/${customerId}`),
};

export default Company;
