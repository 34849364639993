import React, { lazy, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';

import { getMeAsync } from 'stateManager/slices/authSlice';

// ----------------------------------------------------------------------

export default function Router() {
  const dispatch = useDispatch();
  const [currentStatus, setCurrentStatus] = useState('pending');
  const { isAuthorized, currentUserData, status } = useSelector((state) => state.auth);
  const getCurrentUser = () => {
    dispatch(getMeAsync());
  };

  useLayoutEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthorized && status === 'idle' && currentUserData) {
      setCurrentStatus('auth');
    }
    if (!isAuthorized && status === 'idle') {
      setCurrentStatus('not-auth');
    }
  }, [isAuthorized, currentUserData, status]);

  const isLoggedIn = isAuthorized && currentUserData;

  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to={isLoggedIn ? '/dashboard/companies' : '/auth/login'} replace />,
    },
    !isLoggedIn && {
      path: 'auth',
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register/:uuid', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    isLoggedIn && {
      path: 'auth',
      children: [
        { path: 'login', element: <Navigate to='/dashboard/companies' replace /> },
        { path: 'register/:uuid', element: <Navigate to='/dashboard/companies' replace /> },
      ],
    },
    // Dashboard Routes
    isLoggedIn && {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'companies',
          element: <Companies role={'admin'} />,
        },
        {
          path: 'company-details/:companyId',
          element: <CompanyDetails role={'admin'} />,
        },
        'admin' && { path: 'users', element: <Users /> },
        {
          path: 'user-account',
          element: <UserAccount />,
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        {
          path: '*',
          element: <Navigate to={isLoggedIn ? '/404' : '/auth/login'} replace />,
        },
      ],
    },
  ]);

  return currentStatus === 'pending' ? <>Loading...</> : routes;
}

// IMPORT COMPONENTS

// Authentication
const Login = lazy(() => import('../pages/auth/Login'));
const Register = lazy(() => import('../pages/auth/Register'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
const NewPassword = lazy(() => import('../pages/auth/NewPassword'));
const VerifyCode = lazy(() => import('../pages/auth/VerifyCode'));
// Dashboard
const Companies = lazy(() => import('../pages/dashboard/Companies'));
const CompanyDetails = lazy(() => import('../pages/dashboard/CompanyDetails'));
const Users = lazy(() => import('../pages/dashboard/Users'));
const UserAccount = lazy(() => import('../pages/dashboard/UserAccount'));
// Main
const Page500 = lazy(() => import('../pages/Page500'));
const NotFound = lazy(() => import('../pages/Page404'));
