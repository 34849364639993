import ApiClient from './base';

const Auth = {
  changePassword: (data) => ApiClient.post('/auth/change-password/', data),
  forgetPassword: (data) => ApiClient.post('/auth/forget-password/', data),
  logIn: (data) => ApiClient.post('/auth/login/', data),
  resetPassword: (data) => ApiClient.post('/auth/reset-password/', data),
  signUp: (data) => ApiClient.post('/auth/signup/', data),
  checkInvitationId: (uuid) => ApiClient.get(`/auth/check-invitation/${uuid}/`),
  getMe: () => ApiClient.get('/users/me/'),
};

export default Auth;
