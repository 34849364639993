import ApiClient from './base';

const Users = {
  getUsers: ({ page, page_size }) => ApiClient.get(`/users/?page=${page}&page_size=${page_size}`),
  deleteUser: (id) => ApiClient.delete(`/users/${id}`),
  getUsersInvitations: () => ApiClient.get('/users/invitations/'),
  sendUserInvitation: (data) => ApiClient.post('/users/invitations/', data),
};

export default Users;
