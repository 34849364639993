import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Company from 'services/api/company';

export const getCompanyFilterOptionsAsync = createAsyncThunk(
  'company/getFilterOptions',
  async () => {
    try {
      const response = await Company.getFilterOptions();
      return response.data;
    } catch (e) {
      throw new Error(e);
    }
  },
);

export const getCompaniesAsync = createAsyncThunk('company/getCompanies', async (data) => {
  try {
    const response = await Company.getCompanies(data);
    return response;
  } catch (e) {
    throw new Error(e);
  }
});

export const getCompanyDetailsAsync = createAsyncThunk('company/getDetails', async (id) => {
  try {
    const response = await Company.getCompanyDetails(id);
    return response;
  } catch (e) {
    throw new Error(e);
  }
});

export const getSingleCompanyBranches = createAsyncThunk('company/getBranches', async (data) => {
  try {
    const response = await Company.getSingleCompanyBranches(data);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
});

export const getSingleCompanyCustomers = createAsyncThunk('company/getCustomers', async (data) => {
  try {
    const response = await Company.getSingleCompanyCustomers(data);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
});

export const createCompany = createAsyncThunk(
  'company/createCompany',
  async (data, { rejectWithValue }) => {
    try {
      await Company.createCompany(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const uploadCSVCompanyAsync = createAsyncThunk(
  'company/uploadCompanyCSV',
  async (data, { rejectWithValue }) => {
    try {
      await Company.companyUploadCSV(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const updateCompanyAsync = createAsyncThunk(
  'company/updateCompany',
  async (data, { rejectWithValue }) => {
    try {
      await Company.updateCompany(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const updateCompanyGeneralInfoAsync = createAsyncThunk(
  'company/updateCompanyGeneralInfo',
  async (data) => {
    try {
      await Company.partialUpdateCompany(data);
    } catch (e) {
      throw new Error(e);
    }
  },
);

export const deleteCompanyAsync = createAsyncThunk('company/deleteCompany', async (id) => {
  try {
    await Company.deleteCompany(id);
  } catch (e) {
    throw new Error(e);
  }
});

export const createBranchAsync = createAsyncThunk('company/createBranch', async (data) => {
  try {
    await Company.createBranch(data);
  } catch (e) {
    throw new Error(e);
  }
});

export const deleteBranchAsync = createAsyncThunk('company/deleteBranch', async (data) => {
  try {
    await Company.deleteBranch(data);
  } catch (e) {
    throw new Error(e);
  }
});

export const updateBranchAsync = createAsyncThunk('company/updateBranch', async (data) => {
  try {
    await Company.updateBranch(data);
  } catch (e) {
    throw new Error(e);
  }
});

export const createCustomerAsync = createAsyncThunk(
  'company/createCustomer',
  async ({ companyId, data }) => {
    try {
      await Company.createCustomer({ companyId, data });
    } catch (e) {
      throw new Error(e);
    }
  },
);

export const updateCustomerAsync = createAsyncThunk('company/updateCustomer', async (data) => {
  try {
    await Company.updateCustomer(data);
  } catch (e) {
    throw new Error(e);
  }
});

export const deleteCustomerAsync = createAsyncThunk('company/deleteCustomer', async (data) => {
  try {
    await Company.deleteCustomer(data);
  } catch (e) {
    throw new Error(e);
  }
});

const initialState = {
  status: '',
  companies: { count: 0, next: null, previous: null, results: [] },
  companyDetails: {},
  companyBranches: { count: 0, next: null, previous: null, results: [] },
  companyCustomers: { count: 0, next: null, previous: null, results: [] },
  companyFilterOptions: {},
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCompaniesAsync.pending, (state) => {
        state.status = 'loading';
        state.companies = { count: 0, next: null, previous: null, results: [] };
      })
      .addCase(getCompaniesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.companies = action.payload.data;
      })
      .addCase(getCompaniesAsync.rejected, (state) => {
        state.status = 'idle';
        state.companies = { count: 0, next: null, previous: null, results: [] };
      })
      .addCase(getCompanyDetailsAsync.pending, (state) => {
        state.status = 'loading';
        state.companyBranches = [];
      })
      .addCase(getCompanyDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.companyDetails = action.payload.data;
      })
      .addCase(getCompanyDetailsAsync.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(getSingleCompanyBranches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSingleCompanyBranches.fulfilled, (state, action) => {
        state.status = 'idle';
        state.companyBranches = action.payload;
      })
      .addCase(getSingleCompanyBranches.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(getSingleCompanyCustomers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSingleCompanyCustomers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.companyCustomers = action.payload;
      })
      .addCase(getSingleCompanyCustomers.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(getCompanyFilterOptionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCompanyFilterOptionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.companyFilterOptions = action.payload;
      })
      .addCase(getCompanyFilterOptionsAsync.rejected, (state) => {
        state.status = 'idle';
      });
  },
});

export default companySlice.reducer;
