import { configureStore } from '@reduxjs/toolkit';

import authReducer from 'stateManager/slices/authSlice';
import usersReducer from 'stateManager/slices/userSlice';
import companyReducer from 'stateManager/slices/companySlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    company: companyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
