import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Users from 'services/api/users';

export const sendUserInvitationAsync = createAsyncThunk('user/sendInvitation', async (data) => {
  try {
    await Users.sendUserInvitation(data);
  } catch (e) {
    throw new Error(e);
  }
});

export const getUsersAsync = createAsyncThunk('user/getUsers', async (data) => {
  try {
    const response = await Users.getUsers(data);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
});

export const deleteUserAsync = createAsyncThunk('user/delete', async (id) => {
  try {
    await Users.deleteUser(id);
  } catch (e) {
    throw new Error(e);
  }
});

const initialState = {
  status: '',
  users: { count: 0, next: null, previous: null, results: [] },
};

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state) => {
        state.status = 'loading';
        state.users = { count: 0, next: null, previous: null, results: [] };
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(getUsersAsync.rejected, (state, action) => {
        state.status = 'idle';
      });
  },
});

export default usersSlice.reducer;
