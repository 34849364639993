import React from 'react';
// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import SvgIconStyle from 'components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  users: getIcon('ic_users'),
};

const navConfig = (role) => {
  const navOptions = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      items: [
        {
          title: 'Companies',
          path: PATH_DASHBOARD.general.companies,
          icon: ICONS.dashboard,
        },
      ],
    },
  ];

  if (role === 'admin') {
    navOptions[0].items = [
      ...navOptions[0].items,
      {
        title: 'Users',
        path: PATH_DASHBOARD.general.users,
        icon: ICONS.users,
      },
    ];
  }
  return navOptions;
};

export default navConfig;
