export const getAccessToken = (rememberValue) => {
  const token =
    rememberValue === 'remember'
      ? localStorage.getItem('jwt-access-token')
      : sessionStorage.getItem('jwt-access-token');

  return token;
};

export const getRefreshToken = (rememberValue) => {
  const token = rememberValue
    ? localStorage.getItem('jwt-refresh-token')
    : sessionStorage.getItem('jwt-refresh-token');

  return token;
};

export const setAccessToken = (access, storage) => {
  storage.setItem('jwt-access-token', access);
};

export const setToken = async (access, refresh, storage) => {
  storage.setItem('jwt-access-token', access);
  storage.setItem('jwt-refresh-token', refresh);
  await null;
};

export const setRemember = (value) => {
  localStorage.setItem('remember-user', value);
};

export const getRememberValue = () => {
  return localStorage.getItem('remember-user');
};

export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};
