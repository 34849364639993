import React, { Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider>
        <Suspense fallback={'Loading ...'}>
          <Router />
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
