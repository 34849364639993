import axios from 'axios';
import {
  clearStorage,
  getAccessToken,
  getRefreshToken,
  getRememberValue,
  setAccessToken,
} from 'helpers/storage';

const baseConfig = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const ApiClient = axios.create(baseConfig);

let isAlreadyRetried = false;
let subscribedActions = [];

const invokeSubscribedActions = (accessToken) => {
  subscribedActions.map((action) => action(accessToken));
};

const requestOnFullFilled = (config) => {
  const rememberValue = getRememberValue();
  const token = getAccessToken(rememberValue);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const requestOnRejected = (error) => {
  return Promise.reject(error);
};

const refreshToken = async () => {
  try {
    isAlreadyRetried = true;
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      window.location.pathname = '/auth/login';
      clearStorage();
      return;
    }

    const result = await ApiClient.post('auth/refresh-token/', {
      refresh: refreshToken,
    });
    const storage = getRememberValue() ? localStorage : sessionStorage;
    setAccessToken(result.data.access, storage);
    invokeSubscribedActions(result.data.access);
  } catch (err) {
    window.location.pathname = '/auth/login';
    clearStorage();
    console.log('Error while getting by refresh token', err.message);
  }
  subscribedActions = [];
  isAlreadyRetried = false;
};

function addSubscriber(callback) {
  subscribedActions.push(callback);
}

const responseOnRejected = async (error) => {
  if (
    (error.response?.data?.code === 'token_not_valid' || error?.response?.status === 401) &&
    window.location.pathname !== '/auth/login' &&
    !window.location.pathname.includes('register')
  ) {
    const originalRequest = error.config;
    if (!isAlreadyRetried) {
      refreshToken();
    }
    addSubscriber((accessToken) => {
      originalRequest.headers.Authorization = 'Bearer ' + accessToken;
      return ApiClient(originalRequest);
    });
  }
  throw error.response.data;
};

ApiClient.interceptors.request.use(requestOnFullFilled, requestOnRejected);

ApiClient.interceptors.response.use(undefined, responseOnRejected);

export default ApiClient;
