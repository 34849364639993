import React from 'react';
import Avatar from './Avatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

const userData = {
  photoURL: 'google.com',
  displayName: 'username',
};

export default function MyAvatar({ ...other }) {
  const { user } = userData;

  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
