//
import Card from './Card';
import Tabs from './Tabs';
import Menu from './Menu';
import Link from './Link';
import Lists from './List';
import Table from './Table';
import Alert from './Alert';
import Paper from './Paper';
import Input from './Input';
import Radio from './Radio';
import Drawer from './Drawer';
import Dialog from './Dialog';
import Avatar from './Avatar';
import Button from './Button';
import SvgIcon from './SvgIcon';
import Popover from './Popover';
import Checkbox from './Checkbox';
import Typography from './Typography';
import Pagination from './Pagination';
import Breadcrumbs from './Breadcrumbs';
import CssBaseline from './CssBaseline';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Tabs(theme),
    Card(theme),
    Menu(theme),
    Link(theme),
    Input(theme),
    Radio(theme),
    Lists(theme),
    Table(theme),
    Paper(theme),
    Alert(theme),
    Button(theme),
    Dialog(theme),
    Avatar(theme),
    Drawer(theme),
    Popover(theme),
    SvgIcon(theme),
    Checkbox(theme),
    Typography(theme),
    Pagination(theme),
    Breadcrumbs(theme),
    CssBaseline(theme),
  );
}
